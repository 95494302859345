import store from '@/store'
export default function price(data){
    if(data[3] == 'Металлический Россия'){
       if(store.state.role === '5' || store.state.role === '6')
            return store.state.mr/store.state.usd
        else
            return store.state.mr;
    }
    else if(data[3] == 'Металлический импорт'){
       if(store.state.role === '5' || store.state.role === '6')
            return store.state.mi/store.state.usd
        else
            return store.state.mi;
    }
    else if(data[3] == 'Металлический BMW'){
        if(store.state.role === '5' || store.state.role === '6')
            return store.state.mb/store.state.usd
        else
            return store.state.mb;
    }else{
        let pricePt = ((store.state.defPt*(store.state.lmePt/100))/31.1)*(data[0]*10);
        let pricePd = ((store.state.defPd*(store.state.lmePd/100))/31.1)*(data[1]*10);
        let priceRh = ((store.state.defRh*(store.state.lmeRh/100))/31.1)*(data[2]*10);
        //console.log(store.state.defPt)
        return pricePt + pricePd + priceRh
    }
}
