import localizeFilter from '@/filter/localize.filter'
export default function  getCurrency(){
    this.$Progress.start()
    return axios.get('/dashboard.php',
        {
            params:
            {
                //type: 27 get 10 last currencies
                type:30
            }
        })
        .then(response => {
            if(response.data.lenght != 0 || response.data != -10){
               // this.$data.currency = response.data;
               this.$data.date = response.data[0].date
               this.$data.id = response.data[0].id
               this.$data.pt = response.data[0].pt
               this.$data.pd = response.data[0].pd
               this.$data.rh = response.data[0].rh
            }else
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}
