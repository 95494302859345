import localizeFilter from '@/filter/localize.filter'
import changeToDots from '@/filter/dots.filter'
export default function  addCurrencyAUD(){
    if(this.$data.pt != '' && !Number.isInteger(parseInt(this.$data.pt.replace('.', ',')))){
        UIkit.notification({message: localizeFilter('Pt_non_integer')})
    }
    else if(this.$data.pd != '' && !Number.isInteger(parseInt(this.$data.pd.replace('.', ',')))){
        UIkit.notification({message: localizeFilter('Pd_non_integer')})
    }
    else if(this.$data.rh != '' && !Number.isInteger(parseInt(this.$data.rh.replace('.', ',')))){
        UIkit.notification({message: localizeFilter('Rh_non_integer')})
    }
    else{
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type:29,
                    id: this.$data.id,
                    pt: changeToDots(this.$data.pt),
                    pd: changeToDots(this.$data.pd),
                    rh: changeToDots(this.$data.rh)
                }
            })
            .then(response => {
                if(response.data.lenght != 0 || response.data != -10){
                    UIkit.notification({message: localizeFilter('Changed')})
                }else
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}