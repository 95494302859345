import localizeFilter from '@/filter/localize.filter'
export default function PageSearch(what, flag = true){
    this.$Progress.start()
    axios.get('/search.php',
        {
            params:
            {
                type: 1,
                what: what.replace('о', '0').replace('О', '0')
            }
        })
        .then(response => {
            //console.log(response.data);
             if(response.data != -10){
                if(flag)
                    what == '' ? this.$data.preview = '' : this.$data.preview = response.data.splice(0, 10)
                else{
                    this.$data.results = response.data;
                    this.$data.allresults = response.data;
                }
            }else{
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            }
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}
