export default function ShowModal(param){
    this.modal = []
    if(param[12] === "0"){
        param[11] = 'error'
    }
    this.images = param[10]
    this.modal = param
    try {
        UIkit.dropdown('#preview').hide()
        UIkit.modal('#modal-center-one', {container: '#mod'}).show()
    } catch (error) {
        UIkit.modal('#modal-center-one', {container: '#mod'}).show()
    }
}