<template>
    <div class="uk-grid uk-grid-medium" data-uk-grid>
        <!-- panel -->
        <div class="uk-width-1-1">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <form @submit.prevent="addCurrencyAUD">
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Date" | localize}}</label>
                                <input class="uk-input" type="text" v-model='date' disabled>
                            </div>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Pt" | localize}}</label>
                                <input class="uk-input" type="text" v-model='pt' required>
                            </div>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Pd" | localize}}</label>
                                <input class="uk-input" type="text" v-model='pd' required>
                            </div>
                            <div class="uk-width-1-4@s">
                                <label class="uk-form-label ">{{"Rh" | localize}}</label>
                                <input class="uk-input" type="text" v-model='rh' required>
                            </div>
                        </div>
                        <div class="uk-width-1-1@s uk-margin">
                            <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-1@s uk-width-1-3@l uk-align-center uk-margin-medium-top">{{"Save" | localize}}</button>
                        </div>
                    </form>
                </div>
                <!--
                <div class="uk-card-body">
                    <div class="form-container">
                        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;">
                            <thead>
                                <tr>
                                    <th class="">{{"Date" | localize}}</th>
                                    <th class="">{{"Pt" | localize}}</th>
                                    <th class="">{{"Pd" | localize}}</th>
                                    <th class="">{{"Rh" | localize}}</th>
                                </tr>
                            </thead>
                            <tbody v-for="elm in currency">
                                <tr>
                                    <td>{{elm.date}}</td>
                                    <td>{{elm.Pt_aud}}</td>
                                    <td>{{elm.Pd_aud}}</td>
                                    <td>{{elm.Rh_aud}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import getCurrency from '@/config/GetCurrency.dashboard'
import localizeFilter from '@/filter/localize.filter'
import addCurrencyAUD from '@/config/AddCurrencyAUD.dashboard'

import Table from '../Table.vue'
export default {
  components: { Table },
    data: () => ({
        currency: [],
        pt:'',
        pd:'',
        rh:'',
        date: '',
        id:''
    }),
    methods:{
        getCurrency, localizeFilter, addCurrencyAUD,
    },
    beforeMount(){
        this.getCurrency()
    }
}
</script>
