import localizeFilter from '@/filter/localize.filter'
export default function Journal(user, event){
    if(event != ''){
        //this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 21,
                    user: user,
                    event: event
                }
            })
            .then(response => {
                if(response.data == -10){
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                //this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                //this.$Progress.fail()
            })
    }
}