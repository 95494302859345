import store from '@/store'

const value = {
    'usd' : '$',
    'rub' : '₽',
    'aud' : 'A$'
}

export default function tofix(key, what){
    key = parseFloat(key)
    if(what == 'Металлический Россия' || what == 'Металлический импорт' || what == 'Металлический BMW' ){
        const valute = store.state.price
        const whatDollar = store.state.role == 5 || store.state.role === 6 ? store.state.aud : store.state.usd
        if(store.state.role === '5' || store.state.role === '6'){
            var convert = valute === 'rub' ? 1 : store.state.aud
            var symbol = valute != 'rub' ? value['usd'] : value['aud']
        }
        else{
            var convert = valute === 'rub' ? 1 : whatDollar
            var symbol = valute != 'rub' ? value[valute] : value['rub']
        }

        return (key/convert).toFixed(2) + ' ' + symbol
    }
    else{
        const valute = store.state.price
        const whatDollar = store.state.role == 5 || store.state.role === 6 ? store.state.aud : store.state.usd
        if(store.state.role === '5' || store.state.role === '6'){
            var convert = valute === 'rub' ? store.state.aud : 1
            var symbol = valute != 'rub' ? value['usd'] : value['aud']
        }
        else{
            var convert = valute === 'rub' ? whatDollar : 1
            var symbol = valute != 'rub' ? value['usd'] : value[valute]
        }

        return (key*convert).toFixed(2) + ' ' + symbol
    }
}
